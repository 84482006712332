import * as React from "react";
import * as styles from "./nav.module.scss";
import {useEffect, useState} from "react";
import classNames from "classnames";
import Link from "gatsby-link";

const Nav = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const onLinkClick = () => {
    setMenuOpened(false);
  };

  const onHamburgerClick = () => {
    setMenuOpened(prevState => !prevState);
  }

  useEffect(() => {
    if (menuOpened) {
      document?.body?.classList?.add('no-scroll');
    } else {
      document?.body?.classList?.remove('no-scroll');
    }
  }, [menuOpened]);

  return (
    <nav className={classNames({
      [styles.nav]: true,
      [styles.menuOpened]: menuOpened,
    })}>
      <button
        aria-label="Menu"
        aria-controls="menu-list"
        className={classNames({
          hamburger: true,
          'hamburger--spin': true,
          'is-active': menuOpened,
          [styles.menuToggle]: true,
        })}
        onClick={onHamburgerClick}
      >
				<span className="hamburger-box">
					<span className={`${styles.hamburgerInner} hamburger-inner`} />
				</span>
      </button>

      <div className={styles.menuList} id="menu-list">
        <ul className={styles.flexContainer}>
          <li className={styles.menuItem}>
            <Link to="/#home" onClick={onLinkClick}>
              Home
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link to="/#work" onClick={onLinkClick}>
              My work
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link to="/about" onClick={onLinkClick}>
              About me
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link to="/#contact" onClick={onLinkClick}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
};

export default Nav;
